@import "../../base/layout";
@import "../../utilities/utilities";

.selection {
    &:before {
        content:"";
        width:100%;
        display:block;
        height:480px;
        background: url(../../../public/static/images/pattern-bg-mobile.svg) no-repeat center,var(--primary-color-green);
        background-size:cover;
        //opacity:0.39;
        @media (min-width: map-get($grid-breakpoints,lg)) {
            height:400px;

        }
    }
    .selection__container {
        position: relative;
        margin-top:-400px;
        margin-bottom: 100px;
        min-height: 540px;
        &:before {
            position: absolute;;
            content:"";
            width:calc(100% - 30px);
            height:100%;
            left:15px;
            top:0;
            border-radius:var(--border-radius-sm);
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

        }

        @media (min-width: map-get($grid-breakpoints,lg)) {
            &:before {
                content:none;
            }
            margin-top:-320px;
            border-radius: 12px;
            background-color: #ffffff;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        }
    }
    .selection__row {
        @extend %justify-center;
        @media (min-width: map-get($grid-breakpoints,lg)) {
            margin:0;
        }
        .selection__left {
          &.selection__left__single {
            @media (min-width: map-get($grid-breakpoints,lg)) {
              padding-top: 64px;
            }
          }
        }
    }
    .selection__features {
        text-align: center;
        background-color: #ffffff;
        padding:40px 20px 40px 20px;
        border-radius: 0 0 4px 4px;
        .selection_heading{
            font-size: 20px;
        }
        h2 {
            line-height: 1.3;
            @media (min-width: map-get($grid-breakpoints,lg)) {
                margin-bottom: 64px;
              }
        }
        h4 {
            position: relative;
            display: inline-block;
            font-size:18px;
            margin-bottom: 16px;
            letter-spacing:0.3px;
            color:#000;
            &:before , &:after {
                display: inline-block;
                position: absolute;
                content:"";
                width:61px;
                height:1px;
                background:#979797;
                opacity:0.23;
                top:50%;

            }
            &:before {
                left:-8px;
                transform: translateY(-50%) translateX(-100%);
            }
            &:after {
                right:-8px;
                transform: translateY(-50%) translateX(100%);;
            }
        }
        ul {
            @media(min-width: map-get($grid-breakpoints,md)) and  (max-width: map-get($grid-breakpoints, lg)) {
                width:70%;
                margin:0 auto;
            }

            li {
                position: relative;
                font-size: 16px;
                line-height: 1.5;
                letter-spacing: 0.2px;
                font-family: var(--font-medium);
                color:#595959;
                padding-left:30px;
                text-align: left;
                h3{
                    font-size: 16px;
                    line-height: 1.5;
                    font-family: var(--font-medium);
                }
                &:before {
                    text-align: center;
                    font-family: "Icon";
                    position: absolute;
                    display:inline-block;
                    content:"\e903";
                    width:14px;
                    height:14px;
                    border-radius: 50%;
                    background:var(--primary-color-green);
                    vertical-align: middle;
                    top:3px;
                    left:0;
                    color:#fff;
                    font-size:7px;
                    line-height: 14px;
                    font-weight: bold;
                }

                & + li {
                    margin-top:24px;
                }
            }
        }
        @media (min-width: map-get($grid-breakpoints,lg)) {
            text-align: left;
            background-color:transparent;
            border-radius:0;
            padding:0;
            h2 {
                margin-bottom:22px;
                line-height: 1.19;
                display: block;
            }
            h4 {
                display: none;
            }
            ul {
                li {
                    font-size:20px;
                    line-height: 1.56;
                    letter-spacing: 0.3px;
                    padding-left: 43px;
                    &:before {
                        width:20px;
                        height:20px;
                        top:5px;
                        font-size: 10px;
                        line-height:20px;
                    }

                    h3{
                        font-size:20px;
                        line-height: 1.56;
                        font-family: var(--font-medium);
                    }
                }
            }
        }
    }
    .selection__links {
        margin-top:40px;
        span {
            display: block;
            font-size:14px;
            line-height: 1.67;
            color: #737373;
            font-family: var(--font-medium);
            a {
                color:#595959;
                text-decoration: underline;
                display: block;
            }
            & + span {
                margin-top:24px;
            }
        }
        span.single_line {
            a {
                display: inline-block;
            }
        }
        @media (min-width: map-get($grid-breakpoints,lg)) {
            span {
                font-size: 16px;
                line-height: 1.5;
                a {
                    display: inline-block;
                }
            }
        }
    }
    .rate_card_button {
      width: 100%;
      margin-bottom: 16px;
      padding: 0 4px;
      font-size: 14px;
      cursor: pointer;
      @media(min-width: map-get($grid-breakpoints, lg)) {
        padding: 0 16px;
      }
      p {
        display: inline;
        border-bottom: solid 1px rgba(0,0,0,0.87);
      }
    }
    .selection__list{
        margin-bottom: 24px;
    }
    .selection__right {

        order:-1;

        @media (min-width: map-get($grid-breakpoints,lg)) {
            display:flex;
            background-color: #fbfbfb;
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
            order: 0;

        }
        .brand_selection{
            position: relative;
            padding: 10px 10px 50px;
            > span{
                position: absolute;
                color: #FDA000;
                bottom: 22px;
                font-size: 14px;
                font-weight: bold;
                i{
                    display: inline-block;
                    font-size: 0.75rem;
                    width: 1rem;
                    height: 1rem;
                    line-height: 1rem;
                    text-align: center;
                    border-radius: 10px;
                    background: #FDA000;
                    color: #fff;
                    font-style: normal;
                    font-weight: bold;
                }
            }
        }
        .more_text{
            display: none;
        }
        .sku_subtitle{
            text-align: center;
            font-family: var(--font-semi-bold);
            font-size: 14px;
            margin-top: 4px;
            margin-bottom: 16px;
            @media (min-width: map-get($grid-breakpoints,lg)) {
                font-size: 18px;
                margin-bottom: 24px;
            }
        }
        .sku_list.disabled {
            opacity: 0.5;
            pointer-events: none;
            .more_text{
                display: block;
                text-align: center;
                font-weight: bold;
                font-size: 18px;
                padding: 20px;
            }
        }
        .selection__right__title{
            display: block;
            padding: 5px;
            font-size: 18px;
            font-family: var(--font-bold);
            @media (min-width: map-get($grid-breakpoints,lg)) {
                font-size: 30px;
            }
        }
        .selection__right__title_insta{
            text-align: center;
            font-size: 18px;
            font-family: var(--font-bold);
            margin-bottom: 1.5rem;
            @media (min-width: map-get($grid-breakpoints,lg)) {
                font-size: 30px;
            }
        }
    }
    .selection__right__title{
        text-align: center;
        padding: 5px;
        font-size: 18px;
        font-family: var(--font-bold);
        @media (min-width: map-get($grid-breakpoints,md)) {
            font-size:32px;
        }
    }
    .selection__left {
        border-radius:0 0 4px 4px;
        @media (min-width: map-get($grid-breakpoints,lg)) {
            padding:105px 67px;
        }
        h2{
            @media (min-width: map-get($grid-breakpoints,md)) {
                font-size:32px;
            }
        }
        ul + h2 {
            margin-top: 30px;
        }

    }
    .selection__selection {
        display:inline-block;
        margin:auto;
        text-align: center;
        background:#fbfbfb;
        padding:24px 20px 40px 20px;
        width:100%;
        h5 {
            letter-spacing: 0.27px;
            margin-bottom: 8px;
            //font-size:18px;
        }
        p {
            font-family: var(--font-medium);
            letter-spacing: 0.27px;
            color:#737373;
            line-height:1.3;
        }
        h2 {
            margin-bottom:64px;
            line-height: 1.3;
            font-size:20px;
        }

        @media (min-width: map-get($grid-breakpoints,lg)) {
            background: transparent;
            padding:0;
            border-radius:0;
            padding:0;
            h5 {
                margin-bottom:16px;
                letter-spacing:0.4px;
            }
            p {
                font-size: 16px;
                color:#737373;
            }
        }
    }
    .selection__inputs {
        width:268px;
        margin-top:24px;
        margin-left:auto;
        margin-right:auto;
        .selection__input_item + .selection__input_item {
            margin-top:16px;
        }
        @media (min-width: map-get($grid-breakpoints,lg)) {
            margin-top:40px;
            .selection__input_item + .selection__input_item {
                margin-top:24px;
            }
        }
    }
}
.selection__mobile_heading {
    background:#fff;
    font-size:20px;
    padding-top: 34px;
    padding-left:36px;
    padding-right: 36px;
    text-align: center;
    border-radius: 4px 4px 0 0;
    padding-bottom:24px;
    line-height:1.4;
    letter-spacing: 0.33px;

}

.device_selection__edit {
    display: inline-block;
    width:20px;
    height:20px;
    background: var(--primary-color-green);
    vertical-align: middle;
    margin-left:30px;
    border-radius:var(--border-radius-sm);
    text-align: center;
    text-decoration: none;
    &:before {
        display:block;
        font-family:"Icon";
        content:"\e90b";
        color:#fff;
        font-size: 10px;
        line-height: 20px;
        font-weight: bold;
    }
    @media (min-width: map-get($grid-breakpoints,md)) {
        margin-left:16px;
    }
}



.selection__selection.selected {

  @media(min-width: map-get($grid-breakpoints,md)) {
    padding: 24px;

  }
    @media (min-width: map-get($grid-breakpoints,lg)) {
        text-align: left;
        padding:70px 50px;
        margin:0;
    }
}


.selection__user__selected {
    @extend %flex;
    @extend %justify-center;
    margin-top:26px;
    &.protection_plan__header {
      justify-content: flex-start;
    }
    @media (min-width: map-get($grid-breakpoints,lg)) {
        justify-content: flex-start;
        margin-top:32px;
    }
    .selected_block {
        span {
            display: block;
            text-align: left;
        }
        & + .selected_block {
            margin-left:20px;
            @media (min-width: map-get($grid-breakpoints,md)) {
                margin-left:48px;
            }
        }
    }
    .selected_block__label {
        font-size: 12px;
        color: #6b6b6b;
        font-family: var(--font-medium);
        margin-bottom:8px;
        @media (min-width: map-get($grid-breakpoints,md)) {
            font-size:14px;
        }
    }
    .selected_block__value {
        font-size: 14px;
        font-family: var(--font-bold);
        line-height:20px;
        @media (min-width: map-get($grid-breakpoints,md)) {
            font-size:16px;
            line-height: 22px;
        }
    }
}


.selection__user__selected_price {
    @extend %flex;
    @extend %justify-center;
    margin-top:24px;
    flex-wrap: wrap;
    &.protection_plan__header {
      .selected__price_block {
        width: 20%;
        .selected__price_block__details {
          display: flex;
          align-items: center;
          justify-content: space-around;
          padding: 16px 20px;
          .price_block__duration {
            font-family: var(--font-medium);
            font-size: 16px;
          }
        }
      }
    }
    &.cross_sell_modal {
      flex-wrap: nowrap;
      @media (min-width: map-get($grid-breakpoints, md))  {
        margin-top:0;
      }
    }
    &.selection__user__selected_price_wp {
      flex-wrap: nowrap;
    }
    @media (min-width: map-get($grid-breakpoints, md)) {
      flex-wrap: wrap-reverse;
    }
    @media (min-width: map-get($grid-breakpoints,lg)) {
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        margin-top:32px;
        &.selection__user__selected_price_wp {
          margin-top: 16px;
        }
    }
    .selected__price_block__details {
        &.selected__price_block__details_wp {
          padding-top: 21px;
          padding-bottom: 21px;
          text-align: center;
        }
        &.selected__price_block__details_modal {
          margin-top: 32px;
          padding-bottom: 19px;
        }
        box-shadow: 0 2px 4px 0 #00000007;
        border:2px solid #cfcfcf;
        background-color: #ffffff;
        border-radius:var(--border-radius-sm);
        padding:8px;
        text-align: center;
        min-width:50px;
        cursor: pointer;
        transition: all 300ms ease-in-out;
        line-height: 1.2;
        //white-space: nowrap;
        @media (min-width: map-get($grid-breakpoints,lg)) {
            min-width:8rem;
            text-align:left;
            line-height: 1;
            padding: 13px;
            &.selected__price_block__details_wp {
              min-width: 8rem;
              padding-top: 21px;
              padding-bottom: 21px;
              text-align: center;
            }
        }
        span {
            display: block;
        }
    }
    .selected__price_block {
      margin-bottom: 10px;
      margin-right:16px;
      min-width:45%;
        input{
            display: none;
            &:checked ~ .selected__price_block__details {
                border-color: #fdb913;
                background-color: rgba(253, 185, 19, 0.2);
            }
        }
        & + .selected__price_block {
            margin-right:12px;
        }
    }
    .selected__price_block_chimney {
        min-width: 150px;
        margin-bottom: 10px;
          input{
              display: none;
              &:checked ~ .selected__price_block__details {
                  border-color: #fdb913;
                  background-color: rgba(253, 185, 19, 0.2);
              }
          }
          & + .selected__price_block {
              margin-right:12px;
          }
          @media (min-width: map-get($grid-breakpoints,md)){
            min-width: 30%;
        }
          @media (min-width: map-get($grid-breakpoints,lg)){
            min-width: 30%;
        }
      }
    .price_block__original , .price_block__duration {
        font-size: 12px;
        color:#919191;
        min-height: 12px;
    }
    .price_block__original {
       text-decoration: line-through;
    }
    .price_block__discounted {
        font-size:32px;
        font-family: var(--font-bold);
        margin:2px 0;
    }
    .price_block__normal {
      font-size: 14px;
      font-family: var(--font-bold);
    }
    @media (min-width: map-get($grid-breakpoints,lg)) {
        span.price_block__duration , span.price_block__discounted {
            display: inline-block;
            vertical-align: middle;
            font-family: var(--font-bold);
        }
        span.price_block__duration {
            margin-left:12px;
        }
        .price_block__original {
            margin-left:20px;
        }
        .price_block__discounted {
            margin:3px 0;
        }
    }
}

.selection__user__selected_price_type {
    @extend %flex;
    @extend %justify-center;
    flex-wrap: wrap;
    justify-content: start;
    margin-bottom: 1rem;
    width: 60%;
    @media (min-width: map-get($grid-breakpoints, md)) {
      flex-wrap: wrap-reverse;
    }
    @media (min-width: map-get($grid-breakpoints,lg)) {
        flex-wrap: wrap;
        align-items: center;
        &.selection__user__selected_price_wp {
          margin-top: 16px;
        }
    }
    .price_block__normal {
        font-size: 16px;
        font-family: var(--font-bold);
        color: #fdb913;
        text-align: center;
      }

    .selected__price_block__details {
        box-shadow: 0 2px 4px 0 #00000007;
        border:2px solid #fdb913;;
        border-radius:var(--border-radius-sm);
        padding:4px;
        text-align: center;
        cursor: pointer;
        transition: all 300ms ease-in-out;
        line-height: 1.2;
        //white-space: nowrap;
        @media (min-width: map-get($grid-breakpoints,lg)) {
            line-height: 1;
            padding: 8px;
        }
        span {
            display: block;
        }
    }

    .selected__price_block {
      margin-bottom: 10px;
        input{
            display: none;
            &:checked ~ .selected__price_block__details {
                border-color: var(--primary-color-orange);
                background-color: #fdb913;

                .price_block__normal{
                    color: #ffffff;
                }
            }
        }
        & + .selected__price_block {
            margin-right:12px;
        }
    }

    @media (min-width: map-get($grid-breakpoints,lg)) {
        span.price_block__duration , span.price_block__discounted {
            display: inline-block;
            vertical-align: middle;
            font-family: var(--font-bold);
        }
        span.price_block__duration {
            margin-left:12px;
        }
        .price_block__original {
            margin-left:20px;
        }
        .price_block__discounted {
            margin:3px 0;
        }
    }
}

.note {
    .compare {
        display: block;
        font-size: 12px;
        font-family: var(--font-bold);
        text-decoration: underline;
        cursor: pointer;
        margin-top:10px;
        @media (min-width: map-get($grid-breakpoints,md)) {
            margin-top:1rem;
        }
    }
    p.text {
        margin-top:24px;
        font-family: var(--font-medium);
        font-size:12px;
        line-height:1.67;
        color:#000;
        text-align: left;
        color:#575757;
    }
    @media (min-width: map-get($grid-breakpoints,lg)) {
        line-height: 1.5;
    }
    em {
        font-family: var(--font-bold);
        color:#000;
    }
}

.selection_actions {
    margin-top:32px;
    a {
        max-width: 200px;
        margin:0 auto;
    }
    @media (min-width: map-get($grid-breakpoints,lg)) {
        margin-top:40px;
        a {
            max-width: initial;
        }
    }
}

.selection__selection.selected .selection_actions {
    a {
        display:block;
        & + a {
            margin-top:12px;
        }

    }
    @media (min-width: map-get($grid-breakpoints,lg)) {

        a {
            display: inline-block;
            width:176px;
            padding-left:22px;
            padding-right: 22px;
        }
        a + a {
            margin-top:0;
            margin-left:16px;
        }
    }
}

.selected_product_type {
    margin-top:40px;
    .product__type_tab ,span  {
        display: inline-block;
        vertical-align: middle;
    }
    .product__type_tab {
        background: #fff;
        box-shadow: 0 2px 4px 0 #00000007;
        width:100%;
        @media (min-width: map-get($grid-breakpoints,md)) {
            //margin-right:16px;
            //width:auto;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }
    .product__type_tab a {
        display: inline-block;
        position: relative;
        padding:19px 10px;
        font-size:12px;
        font-family: var(--font-bold);
        text-decoration: none;
        color:#000;
        opacity:0.6;
        transition: opacity 200ms ease-out;
        flex:1 1 auto;
        @media (min-width: map-get($grid-breakpoints,lg)) {
            font-size:14px;
            text-align: center;
            //width:auto;
        }
        &[data-selected="true"] {
            opacity:1;
        }
        &[data-selected="true"]:before {

            position: absolute;
            display: block;
            content:"";
            width:100%;
            height:4px;
            left:0;
            bottom:0;
            background: #fbb713;
        }
    }

}



.selection_modal {
    .selected__price_block__details_modal:not(#a) {
        margin-top:0;
        padding-bottom:8px;

    }
    .selected_product_type {
        margin-top:1rem;
    }
    .selection__note_modal {
      margin-left: 10px;
      font-size: 10px;
      line-height: 1.3;
      font-family: var(--font-medium);
      color: #595959;
      span {
        color: var(--font-color-dark);
        font-weight:bold;
      }
    }
    @media (min-width: map-get($grid-breakpoints , md )) {
        display: flex;
        flex-direction: column;
        height:100%;
    }

    .step_text{
        display: block;
        font-size: 12px;
        color:#252525;
    }
    h5 {
        font-size: 16px;
        color:#081c1b;
        margin: 8px 0;
        padding-right: 0;
        padding-left: 0;
    }
    p {
        font-size: 12px;
        color:#919191;
        line-height: 1.17;
        font-family: var(--font-regular);
    }
    .step_text {
        font-family: var(--font-medium);
    }
    // p , .step_text {
    //     font-family: var(--font-medium);
    // }
    .header {
        display: flex;
        justify-content: space-between;
        .sub_header{

            .step_text {
                display: block;
                font-size: 12px;
                color:#252525;

            }
            h5 {
                font-size: 16px;
                color:#081c1b;
                margin: 8px 0;
                padding-right: 0;
                padding-left: 0;
            }
            p {
                font-size: 12px;
                color:#919191;
                line-height: 1.17;
                font-family: var(--font-regular);
            }
            .step_text {
                font-family: var(--font-medium);
            }
        }

        img {
            padding:0;
            width: 0;
            height: 0;
            z-index:0;
            // display: none;
        }
    }
    .selection__user__selected {
        justify-content: space-between;
    }
    .product__type_tab {
        display:flex;
        a {
            flex:1 1 auto;
            width:auto;
            text-align: center;
            font-size: 12px;
            &[data-selected="true"] {
                background-color:rgba(216,216,216,.2);
            }
        }
    }
    .selection__user__selected_price {
        @media (min-width: map-get($grid-breakpoints , md )) {
            margin-bottom: 1rem;
        }
        .selected__price_block {
            width:30%;
            margin-bottom:0;
        }
    }
    .selection_actions {
        @extend %flex;
        @extend %justify-space-between;
        margin-top:12px;
        box-shadow: 2px 0 4px 0 #00000019;
        background-color: #ffffff;
        padding:16px;
        margin-left:-24px;
        margin-right:-24px;
        .modal_action1 {
            padding:12px 44px;
            margin-right:auto;
        }
        .modal_action2 {
            padding:12px 35px;
        }
        a:only-child {
            margin-left:auto;
        }
        @media (min-width: map-get($grid-breakpoints , sm )) {
            box-shadow: none;
            justify-content: flex-end;
        }
    }
    @media (min-width: map-get($grid-breakpoints , sm )) {
        .selection_actions {
            margin-top:40px;
            padding:0;
            box-shadow: none;
            justify-content: flex-end;
            align-self: flex-end;

            .modal_action2 , .modal_action1 {
                padding-top:16px;
                padding-bottom: 16px;
            }
            a + a {
                margin-left:32px;

            }
            .modal_action2 {
                font-size:16px;
            }
        }
    }
    @media (min-width: map-get($grid-breakpoints , lg )) {
        .selection_actions  {
            margin-top:50px;
            margin-bottom:-35px;
        }
        .modal_action1 {
            margin-right:0 !important;
        }
    }
    @media (min-width: map-get($grid-breakpoints , md )) {
        .header {
            .sub_header{
                .step_text {
                    font-size: 14px;
                }
                h5 {
                    font-size: 24px;
                    margin-top:24px;
                    margin-bottom:12px;
                }
                p {
                    font-size: 14px;
                    line-height: 1.43;
                }
            }

            img {
                padding:0;
                width: 91px;
                height: 67px;
                margin-right: -40px;
                z-index: 10;
            }
        }
        .step_text {
            font-size: 14px;
        }
        h5 {
            font-size: 24px;
            margin-top:24px;
            margin-bottom:12px;
        }
        p {
            font-size: 14px;
            line-height: 1.43;
        }
        .selection__user__selected {

            margin-top:16px;
            justify-content: flex-start;
            & + .selection__user__selected {
                margin-left:40px;
            }
        }
        .product__type_tab {
            width:100%;
        }
        .selected_product_type {
            margin-top:12px;
            margin-bottom:12px;
        }
        .product__type_tab {
            a {
                font-size: 16px;
            }
        }
        .selection__user__selected_price {
            .selected__price_block {
                width:auto;
                flex:1 1 auto;
                @media (min-width: map-get($grid-breakpoints , md )){
                  max-width: 210px;
                }
                & + .selected__price_block {
                    margin-left: 26px;
                }
            }
            .selected__price_block__details {
                text-align: center;
                .price_block__discounted {
                    font-size: 24px;
                }
                .price_block__duration {
                    font-size: 16px;
                    font-family: var(--font-regular);
                }
                .price_block__original {
                    text-align: left;
                    padding-left: 37px;
                }
            }
        }

    }
}

.step01:not(#a) {
    .labels {
        display: block;
        margin-bottom:12px;
        font-size: 14px;
        color:#353535;
        font-family: var(--font-medium);
        margin-top:20px;
        &.protection_plans__modal_label {
          margin-top:20px;
          font-size: 16px;
          @media (min-width: map-get($grid-breakpoints , md )) {
              margin-top:40px;
          }
        }
    }
    .selection__user__selected_price {
        .price_block__discounted {
            font-size: 14px;
        }
    }
    .selected_product_type {
        margin-top:24px;
        margin-bottom:24px;
    }
    .selection__user__selected_price {
        margin-top:0;
    }

    @media (min-width: map-get($grid-breakpoints , md )) {
        .labels {
            font-size:16px;
            margin-top:0;
            margin-bottom: 16px;
        }
        .selected_product_type {
            margin-top:24px;
        }
        .selection__inputs {
            margin-top:20px;
            margin-bottom:0;
        }
        .selection__user__selected_price {
            margin-top:0;
        }
    }

}

.price_block_title__small {
  font-size: 14px;
  text-align: center;
}


.protection_plans__modal_features , .selection_modal {

.selection__features_modal {
    background: transparent !important;
  .features_title {
    font-family: var(--font-bold);
  }
  .device_selection__feature__list {
    margin-top: 12px;
    margin-bottom: 10px;
    @media(min-width: map-get($grid-breakpoints, lg)) {
      margin-top: 24px;
    }
  }
  text-align: left;
  background-color: #ffffff;
  border-radius: 0 0 4px 4px;
  margin-top:12px;
  margin-bottom: 12px;
  @media(min-width: map-get($grid-breakpoints,lg)) {
    padding:40px 20px 40px 20px;
    margin-top: 12px;
  }
    h2 {
        margin-bottom:64px;
        line-height: 1.3;
        display: none;
    }
    h4 {
        position: relative;
        display: inline-block;
        font-size:18px;
        margin-bottom: 16px;
        letter-spacing:0.3px;
        color:#000;
        &:before , &:after {
            display: inline-block;
            position: absolute;
            content:"";
            width:61px;
            height:1px;
            background:#979797;
            opacity:0.23;
            top:50%;

        }
        &:before {
            left:-8px;
            transform: translateY(-50%) translateX(-100%);
        }
        &:after {
            right:-8px;
            transform: translateY(-50%) translateX(100%);;
        }
    }
    ul {

        li {
            position: relative;
            font-size: 12px;
            line-height: 1.5;
            letter-spacing: 0.2px;
            font-family: var(--font-medium);
            color:#595959;
            padding-left:18px;
            text-align: left;
            & + li {
                margin-top:8px;
            }
            &:before {
                position: absolute;
                display:inline-block;
                text-align: center;
                font-family: "Icon";
                content:"\e903";
                font-size:5px;
                line-height: 12px;
                color:#fff;
                width:12px;
                height:12px;
                border-radius: 50%;
                background:var(--primary-color-green);
                vertical-align: middle;
                top:3px;
                left:0;

            }
        }
    }
    @media (min-width: map-get($grid-breakpoints,lg)) {
        text-align: left;
        background-color:transparent;
        border-radius:0;
        padding:0;
        h2 {
            margin-bottom:22px;
            line-height: 1.19;
            display: block;
        }
        h4 {
            display: none;
        }
        ul {
            li {
                line-height: 1.4;
                padding-left: 26px;
                margin-top: 0;
                font-size: 14px;
                &:before {
                    width:16px;
                    height:16px;
                    font-size:8px;
                    line-height: 16px;
                }
                & + li {
                    margin-top:12px;
                }
            }
        }
    }
}
}

.selection_crossell {
    &:before {
        content:"";
        width:100%;
        display:block;
        // height:480px;
        background-size:cover;
        @media (min-width: map-get($grid-breakpoints,lg)) {
            height:21rem !important;
        }
    }

    .selection__container {
        position: relative;
        // margin-top:-400px;
        &:before {
            position: absolute;
            content:"";
            width:calc(100% - 30px);
            height:100%;
            left:15px;
            top:0;
            // border-radius:var(--border-radius-sm);
            // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

        }

        @media (min-width: map-get($grid-breakpoints,lg)) {
            &:before {
                content:none;
            }
            margin-top:-320px;
            // border-radius: 12px;
            // background-color: #ffffff;
            // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        }
    }


    .selection__row {
        @extend %justify-center;
    }
    .selection__features {
        text-align: center;
        background-color: #ffffff;
        padding:40px 20px 40px 20px;
        border-radius: 0 0 4px 4px;
        h2 {
            margin-bottom:64px;
            line-height: 1.3;
            display: none;
        }
        h4 {
            position: relative;
            display: inline-block;
            font-size:18px;
            margin-bottom: 16px;
            letter-spacing:0.3px;
            color:#000;
            &:before , &:after {
                display: inline-block;
                position: absolute;
                content:"";
                width:61px;
                height:1px;
                background:#979797;
                opacity:0.23;
                top:50%;

            }
            &:before {
                left:-8px;
                transform: translateY(-50%) translateX(-100%);
            }
            &:after {
                right:-8px;
                transform: translateY(-50%) translateX(100%);;
            }
        }
        ul {

            li {
                position: relative;
                font-size: 12px;
                line-height: 1.5;
                letter-spacing: 0.2px;
                font-family: var(--font-medium);
                color:#595959;
                padding-left:30px;
                text-align: left;
                &:before {
                    text-align: center;
                    font-family: "Icon";
                    position: absolute;
                    display:inline-block;
                    content:"\e903";
                    width:14px;
                    height:14px;
                    border-radius: 50%;
                    background:var(--primary-color-green);
                    vertical-align: middle;
                    top:2px;
                    left:0;
                    color:#fff;
                    font-size:7px;
                    line-height: 14px;
                    font-weight: bold;

                }

                & + li {
                    margin-top:24px;
                }
            }
        }
        @media (min-width: map-get($grid-breakpoints,lg)) {
            text-align: left;
            background-color:transparent;
            border-radius:0;
            padding:0;
            h2 {
                margin-bottom:22px;
                line-height: 1.19;
                display: block;
            }
            h4 {
                display: none;
            }
            ul {
                li {
                    font-size: 18px;
                    line-height: 1.56;
                    letter-spacing: 0.3px;
                    padding-left: 43px;
                    &:before {
                        width:20px;
                        height:20px;
                        top:0;
                        font-size: 10px;
                        line-height:20px;

                    }
                }
            }
        }
    }
    .selection__links {
        margin-top:40px;
        span {
            display: block;
            font-size:12px;
            line-height: 1.67;
            color: #737373;
            font-family: var(--font-medium);
            a {
                color:#595959;
                text-decoration: underline;
                display: block;
            }
            & + span {
                margin-top:24px;
            }
        }
        @media (min-width: map-get($grid-breakpoints,lg)) {
            span {
                font-size: 16px;
                line-height: 1.5;
                a {
                    display: inline-block;
                }
            }
        }
    }
    .selection__right {

        order:-1;

        @media (min-width: map-get($grid-breakpoints,lg)) {
            display:flex;
            background-color: #fbfbfb;
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
            order: 0;
            h2 {
                display: none;
            }
        }
    }
    .selection__left {
        border-radius:0 0 4px 4px;
        @media (min-width: map-get($grid-breakpoints,lg)) {
            padding:105px 67px;
        }
    }
    .selection__selection {
        display:inline-block;
        margin:auto;
        text-align: center;
        background:#fbfbfb;
        padding:24px 20px 40px 20px;
        width:100%;
        h5 {
            letter-spacing: 0.27px;
            margin-bottom: 8px;
        }
        p {
            font-family: var(--font-medium);
            letter-spacing: 0.27px;
            color:#737373;
        }
        h2 {
            margin-bottom:64px;
            line-height: 1.3;
            font-size:20px;
        }
        @media (min-width: map-get($grid-breakpoints,lg)) {
            background: transparent;
            padding:0;
            border-radius:0;
            padding:0;
            h5 {
                margin-bottom:16px;
                letter-spacing:0.4px;
            }
            p {
                font-size: 16px;
                color:#737373;
            }
        }

    }
    .selection__inputs {
        width:268px;
        margin-top:24px;
        margin-left:auto;
        margin-right:auto;
        .selection__input_item + .selection__input_item {
            margin-top:16px;
        }
        @media (min-width: map-get($grid-breakpoints,lg)) {
            margin-top:40px;
            .selection__input_item + .selection__input_item {
                margin-top:24px;
            }
        }

    }
  }
.protection_plans__modal_parent {
  padding: 40px;
}

.protection_plans__modal {
  display: flex;
  margin-top: 32px;
  margin-right: 80px;
  align-content: center;
  justify-content: space-between;
  .device_details__heading {
    font-size: 14px;
    color:#545353;
  }
  .device_details__value {
    margin-top: 16px;
    font-size: 16px;
    font-weight: bold;
    color: #353535;
  }
}

.protection_plans__modal_textfield {
  margin-top:20px;

  label:not(#a) {
    font-size: 16px !important;
    font-family: var(--font-medium);
    margin-bottom:1rem;
    color:#353535;
  }
  @media(min-width: map-get($grid-breakpoints,lg)) {
      margin-top:40px;
  }
}

.protection_plan_modal_plan_details {
  margin-top:24px;
  padding-right:12px;
  max-height:400px;
  overflow-y:auto;
  @media (min-height: 360px) {
      max-height: 300px;
  }
  .product_card {
    &.product_card__active {
      background-color: rgba(254, 220, 137, 0.4);
      border: solid 1px var(--primary-color-orange);
    }
    cursor: pointer;
    padding:20px;
    border: solid 1px var(--font-color-netural);
    border-radius: 25.7px;
    margin-bottom: 24px;
    .product_card__header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .product_title {
        font-size: 14px;
        font-weight: bold;
        line-height: 1.64;
        max-width: 210px;
        @media(min-width: map-get($grid-breakpoints,lg)) {
          font-size: 20px;
          max-width: 255px;
        }
      }
      .plan_duration {
        text-align: right;
        @media(min-width: map-get($grid-breakpoints,lg)) {
          width: 120px;
          text-align: right;
        }
      }
      .duration_selection {
        text-align: right;
      }
      .plan_price {
        font-size: 20px;
        text-align: right;
        font-weight: bold;
        @media(min-width: map-get($grid-breakpoints,lg)) {
          width: 120px;
          font-size: 24px;

        }
      }
      .plan_duration {
        width: 52px;
        font-size: 12px;
        @media(min-width: map-get($grid-breakpoints,lg)) {
          width: 120px;
          font-size: 14px;
        }
      }
    }
    .protection_plans__modal_note {
        font-size: 12px;
        line-height: 1.5;
        letter-spacing: 0.3px;;
        font-family: var(--font-medium);
      @media(min-width: map-get($grid-breakpoints,lg)) {
        display: block;
        font-size: 10px;
        margin-top: 28px;
      }
    }
  }
}

.cart_alert {
  color: #000;
  text-align: start;
  padding: 20px;
  padding-bottom:50px;
  @media(min-width: map-get($grid-breakpoints, lg)) {
    padding-top:20px;
    padding-bottom:0;
  }
  .cart_alert__title {
    color: var(--font-color-neutral);
    line-height: 1.2;
  }

  .cart_alert__text {
    font-family: var(--font-regular);
    line-height: 1.43;
    margin-top:20px;
    font-size: 16px;
    @media(min-width: map-get($grid-breakpoints, lg)) {
        margin-top:20px;
    }
  }
}


.selection.insta_repair {
    .insta_mobile_text {
        padding-right: 0;
        span {
            color: #686A79;
            font-size: 12px;
            line-height: 1.3;
      }
      &.premium_card_text {
        padding: 0 16px;
      }
      .warning_error{
            
            padding-top: 5px;
            color: var(--primary-color-orange);
            white-space: nowrap;
            font-size: 12px;
            font-family: var(--font-bold);
            span{
                padding-left: 4px;
            }
            span, div{
            vertical-align: bottom;
            }        
        }
    }

    .selection__right {
        display: block;
        background: #fff;
        padding:0;
        padding-top:24px;
        max-width: calc(100% - 30px);


        @media (min-width:  map-get($grid-breakpoints, lg) ) {
            padding:105px 48px;
            max-width: auto;
            background: #fbfbfb;
         }
    }
    .selection__left {
        order:-1;
    }
    .selection__user__selected_price {
        text-align: center;
        display:flex;
        justify-content: space-around;
        margin:10px 10px;
        margin-bottom:15px;
    }

}

.selection__input_item.insta_repair {
    .selection__features {
        .selection__links {
            margin-top:0;
            padding:20px;
                li {
                    font-size: 14px;
                    color:#686A79;
                    line-height: 1.5;
                    @media (min-width:  map-get($grid-breakpoints, lg) ) {
                        padding-left:32px;
                        &:before {
                            top:0px;
                            width:20px;
                            height:20px;
                            font-size:8px;
                            font-weight: bold;
                            line-height: 20px;
                        }
                    }
                    & + li {
                        margin-top:8px;
                    }
             }
        }
    }
    .border_effect {
        border-right: dotted 1px #10000019;
        h6 {
            font-size: 16px;
            line-height: 1.3;
        }
        h3 {
            font-size: 1.125rem;
            line-height: 1.75rem;
            margin-bottom: 0;
        }
        .warning_error{
            padding-top: 5px;
            color: var(--primary-color-orange);
            white-space: nowrap;
            font-size: 12px;
            font-family: var(--font-bold);
            span{
                padding-left: 4px;
            }
            span, div{
                vertical-align: bottom;
            }
        }
    }
    ._card {
        display: flex;
        padding: 0px;

    }
    ._card_content {
        padding:0;
        margin: 0px;
        width: 100%;
        &[data-oneplan="true"] {
            .sec_row {
                padding-right:1rem;
                ._qty_mod {
                    justify-content: flex-end !important;
                }
            }
        }
    }
    ._card_desc {
        font-size: 12px;
        width: 5px;
    }
    ._qty {
        display: inline-block;
        padding-left: 15px;
        padding-right: 15px;
        font-size:12px;
        align-self:center;
        font-family: var(--font-medium);
    }
    .sec_row {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height:88px;
        margin: 0px;
        padding:16px;
        padding-right:40px;
        user-select: none;
        .right_toggle {
            display: inline-flex;
            align-items: center;
            position: absolute;
            width:40px;
            height:100%;
            right:0;
            top:0;
            background-color: #f7f7f7;
            border-top-right-radius: 4px;
            button {
                padding:0;
                margin-left:auto;
                margin-right: auto;
            }
        }
        & > div:first-child {
            padding-left:0;
        }
    }
    ._action_icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width:24px;
        height:24px;
        flex: 0 0 24px;
        border-radius: 50%;
        border: solid 1px #ffffff;
        padding: 0;
        cursor: pointer;
        margin:0;
        @media (min-width:  map-get($grid-breakpoints, lg) ) {
            width:20px;
            height:20px;
            flex:0 0 20px;
        }
        ._icon {
            display:inline-flex;
            align-items: center;
            justify-content: center;
            width:10px;
            height:10px;
            text-decoration: none;

            &:after {
                display: inline-block;
                font-family: "Icon";
                font-size:8px;
                color:#fff;
                font-weight: bolder;
            }
            &.plus:after {
                content:"\e90c";
            }
            &.minus:after {
                content:"\e90a";
            }

        }
        &.white {

            border-color:#dadada;
            ._icon:after {
                color:#2f2f2f;
            }
        }

    }
    ._qty_mod {
        display: inline-flex;
        vertical-align: middle;
        padding-left: 15px;
        padding-right: 0px;
        text-align: right;
        .qty_count  {
            font-size:10px;
            color:#000;
        }
    }
    .white {
        color : black;
        background-color: white;

    }
    .white_row {
        &:first-child {
            padding-top: 10px;
        }
        &:not(:last-child) {
            padding-left: 3px !important;
            padding-right: 3px !important;
            margin:0;
        }
    }
    .green {
        background-color: #00a292;
        color: white;
    }
    .gray {
        background-color: #f7f7f7;
    }

}

.bottom_cta {
    margin-top:25px;
    padding:15px 0;
    border-top: solid 1px rgba(151,151,151,0.13);
    margin-right:20px;
    margin-left:20px;
    margin-bottom:15px;

    ._summary {
        padding:0 10px;
    }
    & > div > div {
        &:first-child {
            padding-left:0;
        }
        &:last-child {
            padding-right:0;
        }
        a {
            display: block;
        }
    }
}

.bottom_fixed {
    position:fixed;
    left: 0;
    bottom: 0;
    background-color:#fff;
    width:100%;
    box-shadow: 2px 0 4px 0 #00000019;
    transform: translateY(100%);
    transition: transform 300ms ease-in-out;
    z-index:2;
    &.open {
        transform: translateY(0);
    }
    ._summary span {
        color: #696969;
        font-size: 14px;
    }
    h6 {
        font-size:20px;
    }
    a {
        display:block;
        @media (min-width:  map-get($grid-breakpoints, sm) ) and (max-width:  map-get($grid-breakpoints, lg) ) {
            max-width: 200px;
            margin-left:auto;
        }

    }
}


.insta_modal.selection_modal {
    .insta_dropdown {

        & > span {
            font-family: var(--font-bold);
        }
    }
    .mobile_text , .insta_mobile_text  {
        span {
            font-size: 13px;
            line-height: 1.3;
        }
    }
    .selected__price_block + .selected__price_block {
        margin-left:0;
    }
    .selection__container {
        padding:0;
    }
    .selection__user__selected_price {
        flex-wrap: nowrap;
        // padding-bottom:50px;
        justify-content: space-between;
        @media (min-width:  map-get($grid-breakpoints, md) ) {
            padding-bottom:0;
            flex-wrap: wrap;
        }
        @media (min-width:  map-get($grid-breakpoints, lg) ) {
            margin-top:0;
        }
    }
    .selected__price_block {
        width:47%;
        max-width: 47%;
        margin:0;
    }
    .sub_t {
        padding-bottom:23px;
        border-bottom:1px solid rgba(151,151,151,0.2);
        margin-bottom:20px;
        font-family: var(--font-medium) !important;
        color: #081c1b;
    }
    .selection_actions {
        justify-content: space-between;
        a {
            margin: initial;
        }
        a:only-child {
            margin-left:auto;
        }
    }
    @media (min-width:  map-get($grid-breakpoints, lg)) {
        .selection_actions {
            justify-content: flex-end;
            a + a {
                margin-left:30px;
            }
        }
        .price_block__discounted:not(#a) {
            font-size: 16px;
        }
        .insta_dropdown {

            & > span {
                font-size: 16px;
            }
        }
    }
}


.collapse_btn {
    font-size:12px;
    color:#2b2b2b;
}

.selection {
    .selection_center {
        margin:auto;
    }
}



.insta_repair {
    .add_btn {
        display: inline-block;
        font-size: 14px;
        font-family: var(--font-bold);
        color:#2b2b2b;
        text-transform: uppercase;
        cursor: pointer;
        text-align: right;
        &.green {
            color:#fff;
            background-color: #00a292 !important;
        }
        background-color: #fcfcfc !important;
        @media (min-width:  map-get($grid-breakpoints, md) ) {
            margin-top:0;
        }
        border: 2px solid #cfcfcf;
        box-shadow: 2px 1px 2px 1px rgb(0 0 0 / 10%);
        padding: 7px 14px;
        border-radius: 15px;
        &[data-loading="true"] {
            padding: 4.8px;
            margin-left: 9px;
        }
        &[data-loading="true"]:after {
            margin-left:1px;
        }
    }
}

.insta_repair {
    @media (max-width: map-get($grid-breakpoints, sm)) {
        .selection__user__selected_price:not(#a) {
            justify-content: space-around;
            min-width: 150px;
            .selected__price_block {
                margin-right:0;
            }
        }
    }
    .selection__right {
        &:empty {
            width:100%;
            display: flex;
            align-items: center;
            justify-content: center;
            min-height:200px;
            @media (min-width:  map-get($grid-breakpoints, md) ) {
                min-height: initial;
            }
          &:before {
            display: inline-block;
            content:"";
            width:24px;
            height:24px;
            border-radius: 50%;
            vertical-align: middle;
            border:2px solid #cecece;
            border-top-color:var(--primary-color-green);
            margin-left:10px;
            transform:translateY(-50%);
            animation: spin linear 500ms infinite;
            transform-origin: center center;
            background: none;
          }
        }
    }

    .selection__features_mobile:not(#a) {
        padding-bottom: 16px;
        .selection__links {
            padding:0;

        }
    }
}

@keyframes spin {
    0%{
        transform: rotate(-359deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.paylater {
    .selection__left {
        padding:0;
        @media (min-width: map-get($grid-breakpoints , sm )) {
            padding: 20px;
            padding-bottom:0;
        }
        .selection__links {
            li {
                font-size: 14px;
            }
        }
    }
    .selection_crossell {
        &:before {
            content:none;
        }
    }
    .selection__features {
        padding:10px;
    }

}
._btn_disagree{
    font-weight: bolder;
    padding: 14px;
    @media (min-width:map-get($grid-breakpoints,lg)) {
        padding: 10.5px;
    }
}
._btn_iagree{
    font-weight: bolder;
    padding: 14px;
    @media (min-width:map-get($grid-breakpoints,lg)) {
        padding: 10.5px;
    }
}
.selection_actions_common {
    @extend %flex;
    justify-content: center;
    margin-top:20px;
    box-shadow: 2px 0 4px 0 #00000019;
    background-color: #ffffff;
    padding:16px;
    margin-left:-24px;
    margin-right:-24px;


    a {
        min-width:140px;
        & + a {
            margin-left:20px;
        }
    }
    .modal_action1 {
        padding:12px 44px;
        margin-right:auto;
    }
    .modal_action2 {
        padding:12px 35px;
    }
    @media (min-width: map-get($grid-breakpoints , sm )) {
        box-shadow: none;
        justify-content: flex-end;
    }
}
@media (min-width: map-get($grid-breakpoints , sm )) {
    .selection_actions_common {
        margin-top:40px;
        padding:0;
        box-shadow: none;
        justify-content: flex-end;
        align-self: flex-end;

        .modal_action2 , .modal_action1 {
            padding-top:16px;
            padding-bottom: 16px;
        }
        a + a {
            margin-left:32px;

        }
        .modal_action2 {
            font-size:16px;
        }
    }
}
@media (min-width: map-get($grid-breakpoints , lg )) {
    .selection_actions_common  {
        margin-top:60px;
    }
    .modal_action1 {
        margin-right:0 !important;
    }
}



.app_btn {
    &[data-loading="true"] {
        background: #fff !important;
        color:rgba(0,0,0,0.5) !important;
        pointer-events: none;
        user-select: none;
        cursor:wait;
        transition: background 100ms ease-in-out;
    }
    &[data-loading="true"]:after {
        display: inline-block;
        content:""  !important;
        width:5px;
        height:5px;
        border-radius: 50%;
        vertical-align: middle;
        border:2px solid rgba(0,0,0,0.5);
        border-top-color: #fff;
        margin-left:10px;
        transform:translateY(-50%);
        animation: spin linear 500ms infinite;
        transform-origin: center center;
        background: none  !important;
        position: absolute;
        right: 2px;
        top: 50%;
        margin-top: -4px;
    }

}


@keyframes spin {
    0%{
        transform: rotate(-359deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.change_brand_popup{
    text-align: center;
    font-size: 18px;
    font-family: var(--font-semi-bold);
    .info{
        padding: 20px 0px 40px;
    }
    .cta_text{
        padding: 40px;
        border-top: 1px dashed #ddd;
    }
    .cta{
        a{
            width: 180px;
            @media(max-width: 575px){
                margin-bottom: 16px;
            }
            &:first-child{
                margin-right: 30px;
                @media(max-width: 450px){
                    margin-right: 10px;
                }
            }
        }
    }
}


// premium cards:
.premium_cards {
  display: flex;
  align-items: flex-end;
  position: relative;
  margin: 2px 0;
  .watermark {
    width: 64px;
    height: 24px;
    margin-right: 4px;
    &.drawer_header {
      width: 108px;
      height: 35px;
      margin-bottom: 6px;
    }
  }

  .info {
    width: 8px;
    height: 8px;
    max-height: 8px;
    padding: 1px 0;
    background: url(../../../public/static/images/PremiumCards/info.svg) no-repeat center;
    background-size: cover;
    text-align: center;
    color: #686A79;
    font-weight: bold;
    font-size: 6px;
    margin-bottom: 3px;
  }
}

.premium_title {
  font-size: 16px;
}

.compare_plan_button {
  font-size: 12px;
  font-weight: 700;
  color: #fda000;
  position: relative;
  display: inline-block;
  margin-bottom: 16px;
  margin-left: 10px;
  &:after {
    position: absolute;
    display:inline-block;
    content:"";
    font-size:5px;
    line-height: 12px;
    color:#fff;
    width:14px;
    height:14px;
    border-radius: 50%;
    background: url(../../../public/static/images/PremiumCards/arrow_downward.svg) no-repeat center;
    background-size: cover;
    vertical-align: middle;
    right: -20px;
    top: 0;
  }
}
.premium_card_divider {
  border-top: none;
  border-bottom: dashed 1px rgba(0, 0, 0, 0.2);
  margin-bottom: 16px;
}

.root {
    min-width: 275;
    border: 1px solid;
    border-color: rgba(104, 106, 121, 0.5);
    border-radius: '10px',
}

.bullet {
    display: inline-block;
    margin: 0 2px;
    transform: scale(0.8);
}

.title {
    font-size: 14;
}
.pos {
    margin-bottom: 12;
}
.media {
    height: 0;
    padding-top: 56.25%;// 16:9
}

.expand {
    transform: rotate(0deg);
    margin-left: auto;
    transition-property: transform; /* Specify the property to transition */
    transition-duration: 0.2s; /* Specify the duration (in seconds) */
    transition-timing-function: ease-in-out; /* Specify the timing function */
    transition-delay: 0s; /* Specify the delay (optional) */

    &:hover{
        background-color: "transparent";
      }
}

.expandOpen {
 transform: rotate(180deg);
 &:hover {
    background-color: transparent
  }
}

.border {
    border: 2px;
    border-radius: 50%
}
.button {
    &:hover {
        background-color: var(--primary-color-orange),
    }
  }
